import "../css/banner.css";
import banner from "../img/logo.svg";

const Banner = () => {
  return (
    <div className="banner">
      <img style={{ width: '100px', height: 'auto' }} src={banner} alt="Banner" />
      <h2>DCS Tooling Portal</h2>
    </div>
  );
};

export default Banner;
