import uniqid from 'uniqid';

export default function followupDataTransform(initialPayload){
    let followUpData = {
        "form_0" : initialPayload
    }
    followUpData["patient.selectedSSFTxId"] = uniqid()
    followUpData["ssf.rxNumber"] = uniqid()
    followUpData["ssf.txNumber"] = uniqid()
    followUpData["service.ssfID"] = Math.floor(Math.random() * 1000)
    
    return followUpData

}